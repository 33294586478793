<template>
  <b10-legend
    :showing.sync="internalShowing"
  >
    <template
      slot="legend"
    >
      <v-simple-table>
        <template
          #default
        >
          <tbody>
            <tr>
              <td>
                <v-chip
                  :color="TIEMPO_TRABAJADO.colores.manoDeObra"
                  dark
                >
                  <v-icon
                    small
                  >
                    {{ $vuetify.icons.values.manoDeObra }}
                  </v-icon>
                </v-chip>
              </td>
              <td>
                Tiempo de mano de obra
              </td>
            </tr>
            <tr>
              <td>
                <v-chip
                  :color="TIEMPO_TRABAJADO.colores.desplazamiento"
                  dark
                >
                  <v-icon
                    small
                  >
                    {{ $vuetify.icons.values.desplazamiento }}
                  </v-icon>
                </v-chip>
              </td>
              <td>
                Tiempo de desplazamiento
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </template>
  </b10-legend>
</template>

<script>
import internalShowingMixin from '@/mixins/internalShowingMixin'
import { TIEMPO_TRABAJADO } from '@/utils/consts'

export default {
  mixins: [internalShowingMixin],
  data () {
    return {
      TIEMPO_TRABAJADO,
    }
  },
}
</script>
